import defaultUserProfilePic from "../../../assets/images/global/default_user.png";
import {useEffect, useState} from "react";
import {adminDashboardService} from "../../../_services";

const getDate = (dt) => {
    let date = new Date(dt);
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
    let yyyy = date.getFullYear();
    return mm + "/" + dd + "/" + yyyy;
};

const LatestUsers = () => {
    const [latestUsersData, setLatestUsersData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLatestUsers = async () => {
            try {
                setLoading(true);
                const data = await adminDashboardService.getLatestUsers();
                setLatestUsersData(data);
            } catch (error) {
                console.error("Error fetching latest users:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLatestUsers();
    }, []);

    return (
        <div className="border border-light-subtle py-3 px-3 px-sm-2 px-xl-3 bg-white light-shadow rounded-3 mt-3">
            <div className="main-text-dark-color fw-bold-600">
                Latest Users
            </div>
            {loading ? (
                <div className="last-users-list-holder mt-3">
                    <div className="user-line mt-3 main-loading-effect" style={{height: '30px'}}/>
                    <div className="user-line mt-3 main-loading-effect" style={{height: '30px'}}/>
                    <div className="user-line mt-3 main-loading-effect" style={{height: '30px'}}/>
                    <div className="user-line mt-3 main-loading-effect" style={{height: '30px'}}/>
                    <div className="user-line mt-3 main-loading-effect" style={{height: '30px'}}/>
                </div>
            ) : (
                latestUsersData.length > 0 ? (
                    <div className="last-users-list-holder mt-3">
                        {latestUsersData.map(user => (
                            <div className="user-line mt-3" key={user.id}>
                                <div className="d-flex align-items-center w-100">
                                    <div className="profile-pic holder me-2">
                                        <img width="30" height="30" src={defaultUserProfilePic} alt="user profile"
                                             className="profile-pic rounded-circle"/>
                                    </div>
                                    <div className="user-info lh-1">
                                        <div className="user-name small one-line-limit">
                                            {user.full_name && (`${user.full_name.substring(0, 25)}${
                                                user.full_name.length > 25 ? "..." : ""
                                            }`)}
                                        </div>
                                        <div className="user-email text-real-small one-line-limit gray-text-color">
                                            {`${user.email.substring(0, 22)}${
                                                user.email.length > 22 ? "..." : ""
                                            }`}
                                        </div>
                                    </div>
                                    <div className="user-status text-real-small gray-text-color ms-auto">
                                        {getDate(user.created_date)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <></>
                )
            )}
        </div>
    );
};

export default LatestUsers;