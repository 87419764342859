import React from 'react';
import mainLogo from "../../../assets/images/global/primeband_logo.png";
import englishFlag from "../../../assets/images/global/english_flag.png";
import frenchFlag from "../../../assets/images/global/french_flag.png";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Header = () => {
    const {i18n} = useTranslation();

    return (
        <header className="main-header px-3 py-2">
            <div className="d-flex align-items-center justify-content-between">
                <Link to="/" className="logo-holder">
                    <img loading="lazy" src={mainLogo} alt="Holylane" width="120"/>
                </Link>
                <div className="d-flex align-items-center">
                    <button
                        className={`flag-holder p-0 rounded-circle ${i18n.language.startsWith('en') && 'active'}`}
                        onClick={() => i18n.changeLanguage('en')}>
                        <img src={englishFlag} alt="" width="30" className=""/>
                    </button>
                    <button
                        className={`flag-holder p-0 rounded-circle ${i18n.language.startsWith('fr') && 'active'}`}
                        onClick={() => i18n.changeLanguage('fr')}>
                        <img src={frenchFlag} alt="" width="30" className=""/>
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;