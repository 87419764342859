import "./auth.css";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {userAccountService} from "../../_services";
import {BiKey, BiMailSend, BiUser} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {Footer, Header} from "../public/Public";
import {FaSpinner} from "react-icons/fa";

const Login = () => {
    useEffect(() => {
        document.title = "Holylane - Signup - Login";
    }, []);

    const navigate = useNavigate();
    const location = useLocation();

    const {t} = useTranslation();
    const [step, setStep] = useState(1);
    const [isNewUser, setIsNewUser] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({
        full_name: "",
        email: "",
    });

    const onChange = (e) => {
        setUserDetails({
            ...userDetails,
            [e.target.name]: e.target.value,
        });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (step === 1) {
            // Step 1: Initial email submission
            userAccountService.checkOrSignup({email: userDetails.email})
                .then((res) => {
                    if (res.data.status === 200) {
                        if (res.data.isNewUser) {
                            setIsNewUser(true);
                        }
                        setStep(2); // Proceed to code verification
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => setLoading(false));
        } else if (step === 2) {
            // Step 2: Code verification
            userAccountService.verifyCode({
                email: userDetails.email,
                code: verificationCode,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        if (isNewUser) {
                            setStep(3); // New user needs to enter full name
                        } else {
                            // Existing user, login successful
                            userAccountService.saveToken(res.data.jwt);
                            navigate(location.state?.from || "/my-profile", {replace: true});
                        }
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => setLoading(false));
        } else if (step === 3 && isNewUser) {
            // Step 3: New user submits full name
            userAccountService.completeSignup({
                email: userDetails.email,
                full_name: userDetails.full_name,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        userAccountService.saveToken(res.data.jwt);
                        navigate(location.state?.from || "/my-profile", {replace: true});
                    }
                })
                .catch((error) => console.log(error))
                .finally(() => setLoading(false));
        }
    };

    return (
        <div className="h-100 mx-auto holder-of-desktop-page">
            <div className="all-bible-stories-page">
                <Header/>
                <div className="horizontal-line mx-auto"/>
                <div className="authentication-page p-3">
                    <div className="authentication-content rounded-3">
                        <div className="auth-form">
                            <div className="text-center black-text-color fw-bold-500 my-3">
                                You are trying to access members only content. Enter your email to proceed.
                            </div>
                            <form onSubmit={onSubmit}>
                                {step === 1 && (
                                    <div className="form-group mb-4 position-relative">
                                        <div className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                            <BiMailSend size="25" color="#b7b7b7"/>
                                        </div>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder={t("yourEmail")}
                                            name="email"
                                            required
                                            value={userDetails.email}
                                            onChange={onChange}
                                        />
                                    </div>
                                )}
                                {step === 2 && (
                                    <div className="form-group mb-4 position-relative">
                                        <div className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                            <BiKey size="25" color="#b7b7b7"/>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("enterVerificationCode")}
                                            name="code"
                                            required
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />
                                    </div>
                                )}
                                {step === 3 && isNewUser && (
                                    <div className="form-group mb-4 position-relative">
                                        <div className="icon-holder position-absolute top-50 translate-middle-y ms-2">
                                            <BiUser size="25" color="#b7b7b7"/>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("yourFullName")}
                                            name="full_name"
                                            required
                                            value={userDetails.full_name}
                                            onChange={onChange}
                                        />
                                    </div>
                                )}
                                <button
                                    type="submit"
                                    className="btn btn-block main-button-color py-3 w-100 fw-bold"
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <FaSpinner className="spinner" size="20" color="#fff" spin/>
                                    ) : (
                                        step === 1
                                            ? t("sendVerificationCode")
                                            : step === 2
                                                ? t("verifyAndLogin")
                                                : t("completeSignup")
                                    )}
                                </button>
                            </form>
                            {step === 2 && (
                                <div className="small gray-text-color text-center px-2 mt-3">
                                    A verification code has been sent to <span
                                    className="fw-bold-600 main-text-dark-color">{userDetails.email}</span>. If you
                                    don’t see it in your inbox,
                                    check
                                    your spam or junk folders.
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Login;